
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElNotification } from "element-plus";
import PhoneInput from "@/components/Phone/PhoneInput.vue";

import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "auth-register",
  components: {
    Field,
    Form,
    ErrorMessage,
    PhoneInput,
  },
  props: {
    authData: Object,
  },
  setup(props) {
    const errorsBag = ref({});
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // const familiarity_method = ref([
    //   "google",
    //   "instagram",
    //   "linkedin",
    //   "friends",
    //   "exhibition",
    //   "other",
    // ]);
    // const selectedMethod = ref("");

    const register = Yup.object().shape({
      first_name: Yup.string().required("نام الزامی است").label("first_name"),
      // mobile: Yup.string().required("موبایل الزامی است").label("mobile"),
      last_name: Yup.string()
        .required("نام خانوادگی الزامی است")
        .label("last_name"),
      //   familiarity_method: Yup.string()
      //     .required("شیوه‌ی آشنایی را تعیین کنید")
      //     .label("familiarity_method"),
      email: Yup.string().label("ایمیل"),
      password: Yup.string()
        .min(8, "حداقل باید 8 کاراکتر وارد کنید")
        .required("رمزعبور الزامی است")
        .label("confirm"),

      password_confirmation: Yup.string()
        .required("تکرار رمزعبور الزامی است")
        .oneOf([Yup.ref("password"), null], translate("Passwords must match"))
        .label("Password Confirmation"),
    });

    const submitButtonRegister = ref<HTMLButtonElement | null>(null);

    const onSubmitRegister = async (values) => {
      // values.mobile = "+98" + values.mobile;
      values["mobile"] = mobile_num.value;

      ApiService.post("admin/user/create", values)
        .then(() => {
          Swal.fire({
            text: "کاربر با موفقیت ثبت شد",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "باشه",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          setTimeout(() => {
            router.push("/management/users");
          }, 500);
        })
        .catch((err) => {
          Swal.fire({
            text: err.response.data.validation_message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "باشه",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    };

    const mobile_num = ref();
    const handlePhone = (phoneObject) => {
      mobile_num.value = phoneObject.number;
    };

    onMounted(() => {
      //
    });

    return {
      onSubmitRegister,
      register,
      errorsBag,
      //   familiarity_method,+
      //   selectedMethod,
      route,
      translate,
      submitButtonRegister,
      handlePhone,
      mobile_num,
    };
  },
});
